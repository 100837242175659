import React from "react"

import "./research-and-themes-icon.css"

const ResearchAndThemesIcon = () => (
  <div className="icon research-and-themes-icon">
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 161.57 153.1"
      style={{ enableBackground: "new 0 0 161.57 153.1" }}
    >
      <polygon
        class="temporary-dwellings-fill"
        points="80.78,30.62 66.76,0 64.46,33.39 40.39,9.23 50.1,41.37 18.9,26.58 39.44,53.58 4.87,49.96 
	33.77,68.57 0,76.55 33.77,84.52 4.87,103.14 39.44,99.51 18.9,126.51 50.1,111.73 40.39,143.86 64.46,119.71 66.76,153.1 
	80.78,122.48 94.81,153.1 97.11,119.71 121.17,143.86 111.47,111.73 142.67,126.51 122.12,99.51 156.69,103.14 127.79,84.52 
	161.57,76.55 127.79,68.57 156.69,49.96 122.12,53.58 142.67,26.58 111.47,41.37 121.17,9.23 97.11,33.39 94.81,0 "
      />
    </svg>
    <div>Research & Themes</div>
  </div>
)

export default ResearchAndThemesIcon
